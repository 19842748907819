import React from 'react'

export class ErrorLogger extends React.Component {
    async componentWillMount() {
        this.startErrorLog();
    }

    startErrorLog() {

        window.addEventListener('error', async function (event) {
            if (event.error.hasBeenCaught !== undefined) {
                return false
            }

            if (event.error && event.error.hasBeenCaught) {
                event.error.hasBeenCaught = true
            }

            await fetch(`/api/utility/log`, {
                method: 'post',
                headers: { 
                        'Content-Type': 'application/json', 
                        'utility-log-location': window.location.href 
                },
                body: JSON.stringify({
                    message: (event.error && event.error.message || JSON.stringify(event.error)),
                    priority: 2
                })
            })
        })
    }

    render() {
        return this.props.children
    }
}
