import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import FormTextField from '../../components/FormTextField'
import { reduxForm, change } from 'redux-form'
import { required } from '../../utilities/validators'
import { confirmUser, resendConfirmationCode, setSnackbarMessage, clearSnackbarMessage } from '../../redux/actions'
import * as S from '../../utilities/StringConstants'
import '../../styles/index.scss'
import { injectIntl, intlShape } from 'react-intl'
import * as cookies from '../../utilities/cookies'
import queryString from 'query-string'
import Container from '@material-ui/core/Container'
import { isMobile } from './../../utilities/mobileDetector'

const styles = {
	root: {
		background: '#000'
	},
	input: {
		color: '#fff',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const RegistrationConfirmation = ({
	classes,
	history,
	changeCode,
	location,
	login,
	confirm,
	handleSubmit,
	pristine,
	reset,
	submitting,
	intl,
	resendCode,
	showSnackbar
}) => {
	const theme = useTheme()
	
	const [ user, setUser ] = useState(cookies.get('user'))
	const [ email, setEmail ] = useState(null)
	const [ code, setCode ] = useState(null)
	const onSubmitForm = (values) => {
		confirm(values.code, email)
	}

	useEffect(() => {
		let params = queryString.parse(location.search)

		if (params.code) {
			setCode(params.code)
			changeCode(params.code)
		}
		if (params.email) {
			setEmail(params.email)
		}
		if (email && code) {
			confirm(code, email)
		}
	}, [])

	return (
		<Grid container direction='row' style={{ margin: '50px' }}>
			<Grid item style={{ flex: 0.5, marginRight: '50px' }}>
				<Typography
					data-testid="form-registation-header"
					variant='h1'
					style={{
						color: '#fff',
						textAlign: 'center',
						marginBottom: '20px'
					}}
				>
					{intl.formatMessage({ id: 'app.registation.headerText' })}
				</Typography>
				<Typography
					data-testid="form-registation-welcome"
					variant='body2'
					style={{
						color: '#fff',
						marginBottom: '20px'
					}}
				>
					{intl.formatMessage({ id: 'app.registation.welcomeMessageText' })}
				</Typography>
				<Typography
					data-testid="form-registation-start"
					variant='body2'
					style={{
						color: '#fff',
						marginBottom: '20px'
					}}
				>
					{intl.formatMessage({ id: 'app.registation.startMessageText' })}
				</Typography>
				<Typography
					data-testid="form-registation-contactUs"
					variant='body2'
					style={{
						color: '#fff'
					}}
				>
					{intl.formatMessage({ id: 'app.registation.contactUsText' },
						{
							link:
								<a href="https://www.rgnmed.com/support/contact-us" style={{ color: "#fff" }}>
									{intl.formatMessage({ id: 'app.registation.contactUsLinkText' })}
								</a>
						}
					)}
				</Typography>
			</Grid>
			<Grid item style={{ flex: 0.5 }}>
				<form data-testid="form-onboarding" onSubmit={handleSubmit(onSubmitForm)} style={{ width: '100%', margin: '15px' }}>
					<Container maxWidth="sm" >
						<Grid container direction="column" alignItems="center" justifyContent="center"
							spacing={isMobile ? 0 : 10}
							style={{ backgroundColor: theme.palette.secondary.main, marginTop: '0', marginBottom: '0' }}
						>
							<Grid item xs={isMobile ? 9 : 11} style={{ width: '100%', paddingTop: isMobile ? '30px' : '60px', paddingBottom: isMobile ? '30px' : '60px' }}>

								<Grid item>
									<Typography
										data-testid="form-onboarding-sucess"
										variant='h2'
										style={{
											color: '#fff',
											textAlign: 'center',
											fontWeight: '300',
											marginBottom: '45px'
										}}
									>
										{intl.formatMessage({ id: 'app.onboarding.success' })}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										data-testid="form-onboarding-sucess-label"
										variant='h2'
										style={{
											color: '#fff',
											textAlign: 'center',
											fontSize: '17px',
											margin: '0 auto 30px',
											letterSpacing: '0.51px',
											maxWidth: '80%'
										}}
									>
										{intl.formatMessage({ id: 'app.onboarding.success.label' })}
									</Typography>
								</Grid>

								<Grid className="narrow-input-wrapper" item style={{ width: '100%' }}>
									{!email && (
										<FormTextField
											data-testid="form-onboarding-email"
											label={intl.formatMessage({ id: 'app.onboarding.email' })}
											fullWidth
											type="text"
											name="email"
											variant="filled"
											validators={[required]}
										/>
									)}
								</Grid>

								<Grid className="narrow-input-wrapper" item style={{ width: '100%' }}>
									<FormTextField
										data-testid="form-onboarding-verification"
										label={intl.formatMessage({ id: 'app.onboarding.verification' })}
										fullWidth
										type="text"
										name="code"
										variant="filled"
										validators={[required]}
									/>
								</Grid>
								<Grid item style={{ width: '100%' }}>
									<Grid container direction="row" justifyContent="center" alignItems="center" style={{ minWidth: '100%' }}>
										<Grid
											item
											style={{
												width: '100%',
												marginTop: '20px'
											}}
										>
											<Button
												data-testid="form-onboarding-button-submit"
												label="Submit"
												style={{
													margin: '0 0 2.5rem'
												}}
												variant="contained"
												color="primary"
												fullWidth
												type="submit"
												size="large"
											>
												SUBMIT
											</Button>
										</Grid>
										<Grid item>
											<Button
												data-testid="form-onboarding-button-resend"
												variant="text"
												color="secondary"
												onClick={() => {
													resendCode(
														email,
														intl.formatMessage({ id: 'app.onboarding.resendSuccessMessage' }),
														intl.formatMessage({ id: 'app.onboarding.resendFailMessage' })
													)
												}}
											>
												{intl.formatMessage({ id: 'app.onboarding.resendCode' })}
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</form>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
	//isConfirmed: confirmedSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	confirm: (code, email) => dispatch(confirmUser(code, email, ownProps.history)),
	resendCode: (email, success, fail) => dispatch(resendConfirmationCode(email, success, fail)),
	showSnackbar: (message) => dispatch(setSnackbarMessage(message)),
	changeCode: (code) => dispatch(change('confirmRegistration', 'code', code)),
	changeEmail: (email) => dispatch(change('confirmRegistration', 'email', email))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'confirmRegistration'
	}),
	withRouter,
	withStyles(styles)
)

export default enhance(RegistrationConfirmation)
