import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormTextField from '../../components/FormTextField'
import { FormTypeAheadTextField } from '../../components/FormTypeAheadTextField'
import LanguageSelectorV2 from '../../components/LanguageSelectorV2'	
import { reduxForm, change, formValueSelector } from 'redux-form'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { phoneNumber, required } from '../../utilities/validators'
import { connect } from 'react-redux'
import { updateUserDetailsById, fetchCountries, fetchStates, fetchLanguages } from '../../redux/actions'
import { userDetailsSelector, countryListSelector, statesSelector, languagesSelector } from '../../redux/selectors'
import { PhoneNumberField } from '../../components/PhoneNumberField'
import CountrySelector from '../../components/CountrySelector'

const requiredValidator = required


const selector = formValueSelector('userProfile')
const UpdateUserProfile = ({
	intl,
	handleSubmit,
	submitRequest,
	userDetails,
	loadCountries,
	countries,
    languages,
	loadStates,
    loadLanguages,
	states,
	resetCountryState,
    countryId
}) => {
	const [ countryStateChange, setCountryStateChange ] = useState(false)
	const US = 232

	useEffect(() => {
        loadLanguages()
		loadCountries()
		loadStates(US)
	}, [])

    useEffect(() => {
        countryId === US ? setCountryStateChange(true) : setCountryStateChange(false)
	}, countryId)

	const handleCountryChange = (id) => {
		resetCountryState()
	}

	const onSubmitForm = (values) => {
		var user = {
			...userDetails,
			firstName: values.firstName,
			lastName: values.lastName,
			credentials: values.credentials,
			communicationOn: values.languageId,
			team: {
				teamName: values.teamName ? values.teamName : null,
				countryId: values.countryId ? values.countryId : null,
				streetAddress: values.streetAddress ? values.streetAddress : null,
				city: values.city ? values.city : null,
				state: values.state ? values.state : null,
				customPhoneNumber: values.team.phoneNumber ? values.team.phoneNumber : null,
				customPhoneCountryCode: values.phoneCountryCode ? values.phoneCountryCode : null,
				postalCode: values.postalCode ? values.postalCode : null
			}
		}
		submitRequest(user)
	}

	return (
		<form
			data-testid="update-user-profile"
			onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction='column'
				style={{
					padding: '4rem',
					backgroundColor: '#011020'
				}}>
				<Grid item>
					<Typography
						data-testid="update-user-profile-title"
						variant='h1'
						style={{ color: '#fff', marginBottom: '4rem', textAlign: 'center' }}>
						{intl.formatMessage({ id: 'app.profile.edit.title' })}
					</Typography>
				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography
							data-testid="update-user-profile-subtitle"
							variant='h3' style={{ color: '#fff' }}>
							{intl.formatMessage({ id: 'app.profile.edit.subtitle' })}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={6} style={{ paddingBottom: '0' }}>
						<FormTextField
							data-testid="update-user-profile-first-name"
							label={intl.formatMessage({ id: 'app.profile.edit.firstName' })}
							fullWidth
							type='text'
							name='firstName'
							variant='filled'
							validators={[ requiredValidator ]}
						/>
					</Grid>
					<Grid item xs={12} sm={6} style={{ paddingBottom: '0' }}>
						<FormTextField
							data-testid="update-user-profile-last-name"
							label={intl.formatMessage({ id: 'app.profile.edit.lastName' })}
							fullWidth
							type='text'
							name='lastName'
							variant='filled'
							validators={[ requiredValidator ]}
						/>
					</Grid>
					<Grid item xs={12} style={{ paddingBottom: '0' }}>
						<FormTextField
							data-testid="update-user-profile-profession"
							label={intl.formatMessage({ id: 'app.profile.edit.profession' })}
							fullWidth
							type='text'
							name='credentials'
							variant='filled'
						/>
					</Grid>

					<Grid item xs={12}>
						<Typography
							data-testid="update-user-profile-team-info"
							variant='h3' style={{ color: '#fff', fontWeight: '300' }}>
							{intl.formatMessage({ id: 'app.general.languageLabel' })}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={6} data-testid="update-user-profile-language">
						<LanguageSelectorV2
							label={intl.formatMessage({ id: 'app.general.communicationOnLabel' })}
                            fullWidth
							data={languages}
							name="languageId"
                            variant="filled"
                        />
                    </Grid>

					<Grid item xs={12}>
						<Typography
							data-testid="update-user-profile-team-info"
							variant='h3' style={{ color: '#fff', fontWeight: '300' }}>
							{intl.formatMessage({ id: 'app.profile.teamInformation' })}
						</Typography>
					</Grid>
					
					<Grid item xs={12} style={{ paddingBottom: '0' }}>
						<FormTextField
							data-testid="update-user-profile-team-name"
							label={intl.formatMessage({ id: 'app.profile.edit.teamName' }) + '*'}
							fullWidth
							type='text'
							name='teamName'
							variant='filled'
							validators={[ requiredValidator ]}
						/>
					</Grid>
					<Grid item xs={12} sm={4} style={{ paddingBottom: '0' }}>
                        <CountrySelector
							data-testid="update-user-profile-country"
                            suggestions={countries}
							label={intl.formatMessage({ id: 'app.profile.edit.country' })}
                            style={{ color: '#000' }}
                            name='countryId'
                            onCountryChange={handleCountryChange}
                            maxLength={255}
                        />
					</Grid>
					<Grid item xs={12} sm={4} style={{ paddingBottom: '0' }}>
						<PhoneNumberField
							label={intl.formatMessage({ id: 'app.profile.edit.phoneNumber' })}
                            data-testid="update-user-profile-phone"
							name='team.phoneNumber'
							phoneField='team.phoneNumber'
							phoneCountryCodeField='phoneCountryCode'
							validators={[phoneNumber]}
						/>
					</Grid>
					<Grid item xs={12} style={{ paddingBottom: '0' }}>
						<FormTextField
							data-testid="update-user-profile-street"
							label={intl.formatMessage({ id: 'app.profile.edit.streetAddress' })}
							fullWidth
							type='text'
							name='streetAddress'
							variant='filled'
						/>
					</Grid>


					<Grid item xs={12} sm={4} style={{ paddingBottom: '0' }}>
						<FormTextField
							data-testid="update-user-profile-city"
							label={intl.formatMessage({ id: 'app.profile.edit.city' })}
							fullWidth
							type='text'
							name='city'
							variant='filled'
						/>
					</Grid>
					{!countryStateChange && (
						<Grid item xs={12} sm={4} style={{ paddingBottom: '0' }}>
							<FormTextField
								data-testid="update-user-profile-state-filled"
								label={intl.formatMessage({ id: 'app.profile.edit.state' })}
								type='text'
								name='state'
								variant='filled'
								maxLength={255}
							/>
						</Grid>
					)}
					{countryStateChange && (
						<Grid item xs={12} sm={4} style={{ paddingBottom: '0' }}>
							<FormTypeAheadTextField
								data-testid="update-user-profile-state-suggestions"
								label={intl.formatMessage({ id: 'app.profile.edit.state' })}
								suggestions={states.map((s) => s.name)}
								name='state'
							/>
						</Grid>
					)}
					<Grid item xs={12} sm={4} style={{ paddingBottom: '0' }}>
						<FormTextField
							data-testid="update-user-profile-postal"
							label={intl.formatMessage({ id: 'app.profile.edit.postalCode' })}
							fullWidth
							type='text'
							name='postalCode'
							variant='filled'
						/>
					</Grid>

				</Grid>


				<Grid item style={{ textAlign: 'center', paddingTop: '25px' }}>
					<Button
						data-testid="update-user-profile-button-save"
						variant="contained"
						color="primary"
						type='submit'
						size='large'>
						{intl.formatMessage({ id: 'app.profile.edit.save' })}
					</Button>
				</Grid>

			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	userDetails: userDetailsSelector(state),
	initialValues: userDetailsSelector(state),
	countries: countryListSelector(state),
	languages: languagesSelector(state),
	states: statesSelector(state),
    countryId: selector(state, 'countryId')
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	loadCountries: () => dispatch(fetchCountries()),
	loadLanguages: () => dispatch(fetchLanguages()),
	loadStates: (countryId) => dispatch(fetchStates(countryId)),
	submitRequest: (user) => dispatch(updateUserDetailsById(user)),
	resetCountryState: () => dispatch(change('userProfile', 'state', ''))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'userProfile'
	}),
	injectIntl
)

export default enhance(UpdateUserProfile)
