import * as alerts from '../../../apis/alert'
import { createSelector } from 'reselect'
import { checkSubscriptionUpdates } from '../auth'

const FETCH_ALERTS_REQUESTED = 'FETCH_ALERTS_REQUESTED'
const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS'
const FETCH_ALERTS_FAILED = 'FETCH_ALERTS_FAILED'

export const fetchAlert = () => (dispatch) => {
	dispatch({ type: FETCH_ALERTS_REQUESTED, data: {} })

	return alerts.fetchAlerts().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_ALERTS_SUCCESS, 
				data: {
					items: response.alerts,
					hasMore: response.hasMore
				}
			})
		} else {
			return dispatch({ type: FETCH_ALERTS_FAILED, data: {} })
		}
	})
}

const FETCH_ADDITIONAL_ALERTS_REQUESTED = 'FETCH_ADDITIONAL_ALERTS_REQUESTED'
const FETCH_ADDITIONAL_ALERTS_SUCCESS = 'FETCH_ADDITIONAL_ALERTS_SUCCESS'
const FETCH_ADDITIONAL_ALERTS_FAILED = 'FETCH_ADDITIONAL_ALERTS_FAILED'

export const fetchAdditionalAlerts = (offset) => (dispatch) => {
	dispatch({ type: FETCH_ADDITIONAL_ALERTS_REQUESTED, data: {} })

	return alerts.fetchAlerts(offset).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_ADDITIONAL_ALERTS_SUCCESS,
				data: {
					items: response.alerts,
					hasMore: response.hasMore
				}
			})
		} else {
			return dispatch({ type: FETCH_ADDITIONAL_ALERTS_FAILED, data: {} })
		}
	})
}

const FETCH_INVITATIONS_ALERTS_REQUESTED = 'FETCH_INVITATIONS_ALERTS_REQUESTED'
const FETCH_INVITATIONS_ALERTS_SUCCESS = 'FETCH_INVITATIONS_ALERTS_SUCCESS'
const FETCH_INVITATIONS_ALERTS_FAILED = 'FETCH_INVITATIONS_ALERTS_FAILED'

export const fetchDashboardInvitationAlerts = () => (dispatch) => {
	dispatch({ type: FETCH_INVITATIONS_ALERTS_REQUESTED, data: {} })

	return alerts.fetchInvitationAlerts().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_INVITATIONS_ALERTS_SUCCESS, 
				data: {
					items: response.alerts,
					hasMore: response.hasMore
				}
			})
		} else {
			return dispatch({ type: FETCH_INVITATIONS_ALERTS_FAILED, data: {} })
		}
	})
}

const FETCH_CASE_ALERTS_REQUESTED = 'FETCH_CASE_ALERTS_REQUESTED'
const FETCH_CASE_ALERTS_SUCCESS = 'FETCH_CASE_ALERTS_SUCCESS'
const FETCH_CASE_ALERTS_FAILED = 'FETCH_CASE_ALERTS_FAILED'

export const fetchCaseAlert = (id) => (dispatch) => {
	dispatch({ type: FETCH_CASE_ALERTS_REQUESTED, data: {} })

	return alerts.fetchCaseAlerts(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_ALERTS_SUCCESS, data: { items: response.alerts, hasMore: response.hasMore } })
		} else {
			return dispatch({ type: FETCH_CASE_ALERTS_FAILED, data: {} })
		}
	})
}

const PROCESS_INVITATION_REQUESTED = 'PROCESS_INVITATION_REQUESTED'
const PROCESS_INVITATION_SUCCESS = 'PROCESS_INVITATION_SUCCESS'
const PROCESS_INVITATION_FAILED = 'PROCESS_INVITATION_FAILED'
//action 1 is accept, decline is 0
export const processInvitation = (invitationId, action) => (dispatch) => {
	dispatch({ type: PROCESS_INVITATION_REQUESTED, data: {} })
	return alerts.processInvitation(invitationId, action).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchAlert())
			dispatch(fetchDashboardInvitationAlerts())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.success`, isSuccess: true }
			})
			dispatch(checkSubscriptionUpdates())
			return dispatch({
				type: PROCESS_INVITATION_SUCCESS,
				data: response.alertId
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.failed`, isSuccess: false }
			})
			return dispatch({ type: PROCESS_INVITATION_FAILED, data: {} })
		}
	})
}

const DISMISS_OUTLIER_ALERT_REQUESTED = 'DISMISS_OUTLIER_ALERT_REQUESTED'
const DISMISS_OUTLIER_ALERT_SUCCESS = 'DISMISS_OUTLIER_ALERT_SUCCESS'
const DISMISS_OUTLIER_ALERT_FAILED = 'DISMISS_OUTLIER_ALERT_FAILED'

export const dismissOutlierAlert = (id, caseId) => (dispatch) => {
	dispatch({ type: DISMISS_OUTLIER_ALERT_REQUESTED, data: {} })
	alerts.dismissOutlierAlert(id).then((response) => {
		if (response && response.isSuccessful) {
			
			dispatch(caseId ? fetchCaseAlert(caseId) : fetchAlert())
			
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.success`, isSuccess: true }
			})
			return dispatch({ type: DISMISS_OUTLIER_ALERT_SUCCESS })
		} else {
			return dispatch({ type: DISMISS_OUTLIER_ALERT_FAILED, data: {} })
		}
	})
	return
}

const initial = {
	alerts: [],
	invitationAlerts: [],
	hasMore: true,
	isLoading: false
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_ALERTS_SUCCESS:
			return { 
				...state, 
				alerts: action.data.items,
				hasMore: action.data.hasMore
			}
		case FETCH_ADDITIONAL_ALERTS_SUCCESS:
			return {
				...state,
				alerts: [...state.alerts, ...action.data.items],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case FETCH_ADDITIONAL_ALERTS_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case FETCH_ADDITIONAL_ALERTS_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		case FETCH_INVITATIONS_ALERTS_SUCCESS:
			return { 
				...state, 
				invitationAlerts: action.data.items
			}
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.dashboard

export const alertsSelector = createSelector(mainSelector, (state) => {
	if (state && state.alerts) {
		return state.alerts
	}
	return null
})

export const hasMoreAlertsSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingAlertsSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const dashboardInvitationAlertsSelector = createSelector(mainSelector, (state) => {
	return state.invitationAlerts
})