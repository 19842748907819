import { refreshToken } from './auth'
import history from '../utilities/history'

const excludedUrls = [
	'/api/utility/log',
	'/api/authorization/refresh/',
	'/api/subscription'
]

export const authenticatedFetch = (path, params) => {

	function utilityLog(response, params) {
		fetch(`/api/utility/log`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				'utility-log-location': window.location.href
			},
			body:
			JSON.stringify({
				message:
					'Requested Url: ' + response.url + '\n' +
					'Status: ' + response.status + '\n' +
					'Method: ' + params?.method + '\n' +
					'Body: ' + params?.body,
				priority: 2
			})
		})
	}

	// Adding current time to prevent caching
	if(path.includes('?')) {
		path += `&${(new Date).getTime()}`
	} else {
		path += `?${(new Date).getTime()}`
	}

	return fetch(path, { ...params, credentials: 'include' }).then((response) => {
		if (response.headers.get('token-expired') || response.status === 401) {

			//always log when submitting survey
			if (response.url && 
				/\/api\/survey\/\d+\/\d+\/\d+\/\d+/.test(response.url) &&
				params.method  == 'POST') {
				utilityLog(response, params);
			}

			//history.push('/auth/login')
			return refreshToken().then((response) => {
				if (response.status === 200) {
					return fetch(path, { ...params, credentials: 'include' })
				} else {
					history.push('/auth/login')
					return response
				}
			})
		}
		else if ([400, 404, 500].includes(response.status) && !excludedUrls.some(u => response.url.includes(u)))
        {
			utilityLog(response, params);
		}

		return response
	})
}
