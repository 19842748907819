import * as protocol from '../../../apis/observationalProtocol'
import * as survey from '../../../apis/survey'
import { createSelector } from 'reselect'
import { hideModal, fetchCaseProtocol } from '../../actions'
import { Node, LinkList, ListContainer } from '../../../utilities/linkedList'
import history from '../../../utilities/history'
import { uuidv4 } from '../../../utilities/utilities'
import { startSaving, stopSaving } from '../../actions'

function getBilateralRevisions(revisionsList) {
	let bilateralRevisions = revisionsList.length > 0 ? revisionsList[0] : []
	if (revisionsList.length > 1 && revisionsList[0] && revisionsList[1]) {
		bilateralRevisions = revisionsList[0]?.map((revision, index) => {
			var differences = revision?.differences?.map(diff => ({
				...diff,
				area1OldValue: diff.oldValue,
				area1NewValue: diff.newValue,
				area2OldValue: revisionsList[1][index]?.differences?.find(d => d.id === diff.id && d.uniqueId === diff.uniqueId && d.questionType === diff.questionType)?.oldValue,
				area2NewValue: revisionsList[1][index]?.differences?.find(d => d.id === diff.id && d.uniqueId === diff.uniqueId && d.questionType === diff.questionType)?.newValue
			}))

			var missingDifferences = revisionsList[1][index]?.differences?.filter(diff => !differences.find(d => d.id === diff.id && d.uniqueId === diff.uniqueId && d.questionType === diff.questionType))
			if (missingDifferences?.length > 0) {
				missingDifferences = missingDifferences.map(diff => ({
					...diff,
					area1OldValue: null,
					area1NewValue: null,
					area2OldValue: diff.oldValue,
					area2NewValue: diff.newValue,
				}))

				differences = differences.concat(missingDifferences)
			}

			return { ...revision, differences: differences.sort((a, b) => (a.uniqueId > b.uniqueId ? 1 : -1)) }
		})
	}
	return bilateralRevisions
}

const SUBMIT_SURVEY_ANSWER_REQUESTED = 'SUBMIT_SURVEY_ANSWER_REQUESTED'
const SUBMIT_SURVEY_ANSWER_SUCCESS = 'SUBMIT_SURVEY_ANSWER_SUCCESS'
const SUBMIT_SURVEY_ANSWER_FAILED = 'SUBMIT_SURVEY_ANSWER_FAILED'

export const submitSurvey = (caseId, protocolId, instanceId, surveyId, patientId, delegateId, questionAnswers, requestType, status) => (
	dispatch, getState
) => {
	let state = getState().protocol

	let patientCaseSurveyInstances = state.patientCaseSurveyInstances

	let questionAnswerSides = []

	Object.keys(patientCaseSurveyInstances).map(key => {
		questionAnswerSides.push({
			questionAnswers: questionAnswers[parseInt(key)],
			patientCaseSurveyInstanceId: patientCaseSurveyInstances[key].patientCaseSurveyInstanceId,
			bilateralAreaId: patientCaseSurveyInstances[key].bilateralAreaId,
			patientCaseSurveyInstanceVersion: patientCaseSurveyInstances[key].patientCaseSurveyInstanceVersion
		})
	})

	dispatch({ type: SUBMIT_SURVEY_ANSWER_REQUESTED, data: { isCreatingSurvey: true } })
	survey.submitSurvey(caseId, protocolId, instanceId, surveyId, patientId, questionAnswerSides, requestType, status).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			let nextStatus = 1
			dispatch({ type: SUBMIT_SURVEY_ANSWER_SUCCESS, data: { status: nextStatus, isCreatingSurvey: false } })

			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.survey.saveSurveySuccess', isSuccess: true }
			})

			history.push(
				requestType === 'task'
				? `/tasks/${caseId}/status/${nextStatus}`
				: `/cases/${caseId}/survey/${surveyId}/instance/${instanceId}/delegate/${delegateId}/status/${nextStatus}`
			)

			// if(response.surveyId && response.surveyInstanceId){
			// 	history.push(`/survey/${caseId}/${protocolId}/${response.surveyInstanceId}/${response.surveyId}`)
			// }
		} else {
			dispatch({ type: 'SUBMIT_SURVEY_ANSWER_FAILED', data: { isCreatingSurvey: false } })
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.survey.saveSurveyFailed', isSuccess: false }
			})
			fetch(`/api/utility/log`, {
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
					'utility-log-location': window.location.href
				},
				body:
				JSON.stringify({
					message: 
						'Submit survey Error\n' +
						'Survey Url: ' + window.location.href + '\n' +
						'Response: ' + JSON.stringify(response) + '\n',
					priority: 3
				})
			})
		}
	})
}

const FETCH_OBSERVATIONAL_PROTOCOL_REQUESTED = 'FETCH_OBSERVATIONAL_PROTOCOL_REQUESTED'
const FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS = 'FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS'
const FETCH_OBSERVATIONAL_PROTOCOL_FAILED = 'FETCH_OBSERVATIONAL_PROTOCOL_FAILED'

export const fetchProtocolByVersion = (id, versionId) => (dispatch) => {
	if (!id) {
		return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS, data: null })
	}
	dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_REQUESTED, data: {} })
	protocol.getObservationalProtocolByVersion(id, versionId).then((observationProtocol) => {
		if (observationProtocol && observationProtocol.isSuccessful) {
			return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS, data: observationProtocol })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}
// TODO: DEPRECATED
export const fetchProtocolByCircle = (id, circleId) => (dispatch) => {
	if (!id) {
		return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS, data: null })
	}
	dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_REQUESTED, data: {} })
	protocol.getObservationalProtocolByCircle(id, circleId).then((observationProtocol) => {
		if (observationProtocol && observationProtocol.isSuccessful) {
			return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS, data: observationProtocol })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_OBSERVATIONAL_PROTOCOLS_REQUESTED = 'FETCH_OBSERVATIONAL_PROTOCOLS_REQUESTED'
const FETCH_OBSERVATIONAL_PROTOCOLS_SUCCESS = 'FETCH_OBSERVATIONAL_PROTOCOLS_SUCCESS'
const FETCH_OBSERVATIONAL_PROTOCOLS_FAILED = 'FETCH_OBSERVATIONAL_PROTOCOLS_FAILED'
export const fetchProtocols = () => (dispatch) => {
	dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOLS_REQUESTED, data: {} })
	protocol.getObservationalProtocols().then((observationProtocols) => {
		if (observationProtocols && observationProtocols.isSuccessful) {
			return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOLS_SUCCESS, data: observationProtocols.data })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolsFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_SURVEY_REQUESTED = 'FETCH_SURVEY_REQUESTED'
const FETCH_SURVEY_SUCCESS = 'FETCH_SURVEY_SUCCESS'
const FETCH_SURVEY_FAILED = 'FETCH_SURVEY_FAILED'

export const fetchSurvey = (protocolId, surveyId) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_REQUESTED, data: {} })

	protocol.getSurvey(protocolId, surveyId).then((survey) => {
		if (survey && survey.isSuccessful) {
			let questions = new Array(0)
			let hash = {}
			let container = new ListContainer()

			processSurveyQuestions(survey, container, questions, hash)
			return dispatch({
				type: FETCH_SURVEY_SUCCESS,
				data: { survey: survey, list: container, flat: questions, nodes: hash }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchSurveyFailed', isSuccess: false }
			})
		}
	})
}

const FETCH_SURVEY_VALUES_REQUESTED = 'FETCH_SURVEY_VALUES_REQUESTED'
const FETCH_SURVEY_VALUES_SUCCESS = 'FETCH_SURVEY_VALUES_SUCCESS'
const FETCH_SURVEY_VALUES_FAILED = 'FETCH_SURVEY_VALUES_FAILED'

export const fetchSurveyValues = (protocolId, surveyId, instanceId, caseId, patientId, status) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_VALUES_REQUESTED, data: { isCreatingSurvey: false } })

	protocol.getSurveyValues(protocolId, surveyId, instanceId, caseId, patientId).then((survey) => {
		if (survey && survey.isSuccessful) {
			let questionContainerList = []
			let hashList = []
			let containerList = []
			let revisionsList = []
			let patientCaseSurveyInstances = {}

			survey.answeredQuestions.sort((a, b) => (a.bilateralAreaId > b.bilateralAreaId ? 1 : -1)).map((answeredQuestions, index) => {
				let questionContainer = new Array(0)
				let hash = {}
				let container = new ListContainer()

				processSurveyQuestions(answeredQuestions.questions, container, questionContainer, hash)

				questionContainerList.push(questionContainer)
				hashList.push(hash)
				containerList.push(container)
				revisionsList.push(answeredQuestions.revisions)
				patientCaseSurveyInstances[`${index}`] = {
					patientCaseSurveyInstanceId: answeredQuestions.patientCaseSurveyInstanceId,
					bilateralAreaId: answeredQuestions.bilateralAreaId,
					patientCaseSurveyInstanceVersion: answeredQuestions.patientCaseSurveyInstanceVersion
				}
			})

			let bilateralRevisions = getBilateralRevisions(revisionsList)

			return dispatch({
				type: FETCH_SURVEY_VALUES_SUCCESS,
				data: {
					surveyId: surveyId,
					survey: survey,
					list: containerList,
					flat: questionContainerList,
					nodes: hashList,
					status: status,
					revisions: bilateralRevisions,
					patientCaseSurveyInstances: patientCaseSurveyInstances
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchSurveyFailed', isSuccess: false }
			})
		}
	})
}

export const fetchSurveyValuesByTaskId = (surveyId, taskId, status) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_VALUES_REQUESTED, data: { isCreatingSurvey: false } })

	protocol.getTaskSurveyValues(taskId).then((survey) => {
		if (survey && survey.isSuccessful) {
			let questionContainerList = []
			let hashList = []
			let containerList = []
			let revisionsList = []
			let patientCaseSurveyInstances = {}

			survey.answeredQuestions.sort((a, b) => (a.bilateralAreaId > b.bilateralAreaId ? 1 : -1)).map((answeredQuestions, index) => {
				let questionContainer = new Array(0)
				let hash = {}
				let container = new ListContainer()

				processSurveyQuestions(answeredQuestions.questions, container, questionContainer, hash)

				questionContainerList.push(questionContainer)
				hashList.push(hash)
				containerList.push(container)
				revisionsList.push(answeredQuestions.revisions)
				patientCaseSurveyInstances[`${index}`] = {
					patientCaseSurveyInstanceId: answeredQuestions.patientCaseSurveyInstanceId,
					bilateralAreaId: answeredQuestions.bilateralAreaId,
					patientCaseSurveyInstanceVersion: answeredQuestions.patientCaseSurveyInstanceVersion
				}
			})

			let bilateralRevisions = getBilateralRevisions(revisionsList)

			return dispatch({
				type: FETCH_SURVEY_VALUES_SUCCESS,
				data: {
					surveyId: surveyId,
					survey: survey,
					list: containerList,
					flat: questionContainerList,
					nodes: hashList,
					status: status,
					revisions: bilateralRevisions,
					patientCaseSurveyInstances: patientCaseSurveyInstances
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchSurveyFailed', isSuccess: false }
			})
		}
	})
}

const processSurveyQuestions = (surveyQuestions, container, questionContainer, hash) => {
	if (surveyQuestions) {
		surveyQuestions.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).map((r) => {
			container.getList().append(r)
		})
		container.getList().traverse((r) => {
			questionContainer.push(r.data.uniqueId)
			hash[r.data.uniqueId] = r
		})
	}
}

const SAVE_OBSERVATIONAL_PROTOCOL_REQUESTED = 'SAVE_OBSERVATIONAL_PROTOCOL_REQUESTED'
const SAVE_OBSERVATIONAL_PROTOCOL_SUCCESS = 'SAVE_OBSERVATIONAL_PROTOCOL_SUCCESS'
const SAVE_OBSERVATIONAL_PROTOCOL_FAILED = 'SAVE_OBSERVATIONAL_PROTOCOL_FAILED'

export const editProtocolAlias = (protocolId, circleId, alias) => (dispatch) => {

	dispatch({ type: SAVE_OBSERVATIONAL_PROTOCOL_REQUESTED, data: {} })

	protocol.saveObservationalProtocolAlias(protocolId, circleId, alias).then((reponse) => {
        if (reponse && reponse.isSuccessful) {
            dispatch(hideModal())

            dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.success', isSuccess: true }
            })

            dispatch(stopSaving())

			return dispatch(fetchProtocolByCircle(protocolId, circleId))
        } else {
			dispatch(stopSaving())

            return dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: { message: 'app.protocol.saveProtocolFailed', isSuccess: false }
            })
        }
    })
    return
}

const FETCH_SURVEY_QUESTIONS_REQUESTED = 'FETCH_SURVEY_QUESTIONS_REQUESTED'
const FETCH_SURVEY_QUESTIONS_SUCCESS = 'FETCH_SURVEY_QUESTIONS_SUCCESS'
export const fetchSurveyQuestionsByProtocolVersion = (observationProtocolSurveyId, protocolVersionId) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_QUESTIONS_REQUESTED, data: {} })

	protocol.getSurveyQuestionsByProtocolVersion(observationProtocolSurveyId, protocolVersionId).then((survey) => {
		if (survey && survey.isSuccessful) {
			let questionContainerList = []
			let hashList = []
			let containerList = []
			let revisionsList = []
			let patientCaseSurveyInstances = {}

			survey.answeredQuestions.map((answeredQuestions, index) => {
				let questionContainer = new Array(0)
				let hash = {}
				let container = new ListContainer()

				processSurveyQuestions(answeredQuestions.questions, container, questionContainer, hash)

				questionContainerList.push(questionContainer)
				hashList.push(hash)
				containerList.push(container)
				revisionsList.push(answeredQuestions.revisions)
				patientCaseSurveyInstances[`${index}`] = {
					patientCaseSurveyInstanceId: answeredQuestions.patientCaseSurveyInstanceId,
					bilateralAreaId: answeredQuestions.bilateralAreaId,
					patientCaseSurveyInstanceVersion: answeredQuestions.patientCaseSurveyInstanceVersion
				}
			})

			return dispatch({
				type: FETCH_SURVEY_SUCCESS,
				data: {
					survey: survey,
					list: containerList,
					flat: questionContainerList,
					nodes: hashList,
					patientCaseSurveyInstances: patientCaseSurveyInstances
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchSurveyFailed', isSuccess: false }
			})
		}
	})
	return
}
// TODO: DEPRECATED
export const fetchSurveyQuestionsByCircle = (observationProtocolSurveyId, circleId) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_QUESTIONS_REQUESTED, data: {} })

	protocol.getSurveyQuestionsByCircle(observationProtocolSurveyId, circleId).then((survey) => {
		if (survey && survey.isSuccessful) {
			let questions = new Array(0)
			let hash = {}
			let container = new ListContainer()
	
			processSurveyQuestions(survey, container, questions, hash)
			return dispatch({
				type: FETCH_SURVEY_SUCCESS,
				data: { survey: survey, list: container, flat: questions, nodes: hash }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchSurveyFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_PROTOCOL_AUTOCOMPLETE_REQUESTED = 'FETCH_PROTOCOL_AUTOCOMPLETE_REQUESTED'
const FETCH_PROTOCOL_AUTOCOMPLETE_SUCCESS = 'FETCH_PROTOCOL_AUTOCOMPLETE_SUCCESS'
const FETCH_PROTOCOL_AUTOCOMPLETE_FAILED = 'FETCH_PROTOCOL_AUTOCOMPLETE_FAILED'

export const fetchProtocolAutoComplete = (searchPhrase, noMatchesText) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOL_AUTOCOMPLETE_REQUESTED, data: {} })

	protocol.fetchProtocolAutoComplete(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			{
				if (response.protocols && response.protocols.length > 0) {
					return dispatch({ type: FETCH_PROTOCOL_AUTOCOMPLETE_SUCCESS, data: response.protocols })
				} else {
					return dispatch({
						type: FETCH_PROTOCOL_AUTOCOMPLETE_SUCCESS,
						data: [ { id: -1, name: noMatchesText } ]
					})
				}
			}
		} else {
			return dispatch({ type: FETCH_PROTOCOL_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const FETCH_CHILD_PROTOCOL_AUTOCOMPLETE_REQUESTED = 'FETCH_CHILD_PROTOCOL_AUTOCOMPLETE_REQUESTED'
const FETCH_CHILD_PROTOCOL_AUTOCOMPLETE_SUCCESS = 'FETCH_CHILD_PROTOCOL_AUTOCOMPLETE_SUCCESS'
const FETCH_CHILD_PROTOCOL_AUTOCOMPLETE_FAILED = 'FETCH_CHILD_PROTOCOL_AUTOCOMPLETE_FAILED'

export const fetchChildProtocolAutoComplete = (id, searchPhrase) => (dispatch) => {
	dispatch({ type: FETCH_CHILD_PROTOCOL_AUTOCOMPLETE_REQUESTED, data: {} })

	protocol.fetchChildProtocolAutoComplete(id, searchPhrase).then((protocols) => {
		if (protocols) {
			return dispatch({ type: FETCH_CHILD_PROTOCOL_AUTOCOMPLETE_SUCCESS, data: protocols.protocols })
		} else {
			return dispatch({ type: FETCH_CHILD_PROTOCOL_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

export const RESET_SURVEY = 'RESET_SURVEY'
export const resetSurvey = () => (dispatch) => {
	dispatch({ type: RESET_SURVEY, data: {} })
}

const CHANGE_SURVEY_COMPLETED_DATE_REQUESTED = 'CHANGE_SURVEY_COMPLETED_DATE_REQUESTED'
const CHANGE_SURVEY_COMPLETED_DATE_SUCCESS = 'CHANGE_SURVEY_COMPLETED_DATE_SUCCESS'
const CHANGE_SURVEY_COMPLETED_DATE_FAILED = 'CHANGE_SURVEY_COMPLETED_DATE_FAILED'

export const changeSurveyCompletedDate = (caseId, instanceId, newDate, requestType, taskId) => (
	dispatch
) => {
	dispatch({ type: CHANGE_SURVEY_COMPLETED_DATE_REQUESTED, data: { } })
	survey.changeSurveyCompletedDate(caseId, instanceId, newDate).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			dispatch({ type: CHANGE_SURVEY_COMPLETED_DATE_SUCCESS, data: { } })

			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.case.protocol.changeCompletedDateSuccess', isSuccess: true }
			})

			window.location.reload()
		} else {
			dispatch({ type: CHANGE_SURVEY_COMPLETED_DATE_FAILED, data: { } })
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.case.protocol.changeCompletedDateFailed', isSuccess: false }
			})
		}
	})
}

const SET_ARE_ANY_ANSWERED_QUESTION_REQUESTED = 'SET_ARE_ANY_ANSWERED_QUESTION_REQUESTED'
const SET_ARE_ANY_ANSWERED_QUESTION_SUCCESS = 'SET_ARE_ANY_ANSWERED_QUESTION_SUCCESS'
const SET_ARE_ANY_ANSWERED_QUESTION_FAILED = 'SET_ARE_ANY_ANSWERED_QUESTION_FAILED'

export const setAnyAnsweredQuestionState = (state) => (dispatch) => {
    return dispatch({
        type: SET_ARE_ANY_ANSWERED_QUESTION_SUCCESS,
		data: state
    });
}

const FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_REQUESTED = 'FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_REQUESTED'
const FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_SUCCESS = 'FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_SUCCESS'
const FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_FAILED = 'FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_FAILED'

export const fetchProtocolVersionsForCircle = (id) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_REQUESTED, data: {} })

	protocol.fetchVersionsForCircle(id).then((protocols) => {
		if (protocols) {
			return dispatch({ type: FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_SUCCESS, data: protocols.data })
		} else {
			return dispatch({ type: FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_FAILED, data: {} })
		}
	})
	return
}

const CLEAR_PROTOCOL_VERSIONS_REQUESTED = 'CLEAR_PROTOCOL_VERSIONS_REQUESTED'

export const clearProtocolVersions = () => (dispatch) => {
	return dispatch({ type: CLEAR_PROTOCOL_VERSIONS_REQUESTED, data: {} })
}

const FETCH_CAN_RESEND_SURVEY_REMINDER_REQUESTED = 'FETCH_CAN_RESEND_SURVEY_REMINDER_REQUESTED'
const FETCH_CAN_RESEND_SURVEY_REMINDER_SUCCESS = 'FETCH_CAN_RESEND_SURVEY_REMINDER_SUCCESS'
const FETCH_CAN_RESEND_SURVEY_REMINDER_FAILED = 'FETCH_CAN_RESEND_SURVEY_REMINDER_FAILED'

export const fetchCanResendSurveyReminders = (caseId) => (dispatch) => {
	dispatch({ type: FETCH_CAN_RESEND_SURVEY_REMINDER_REQUESTED, data: {} })

	survey.checkCanResendSurveyReminders(caseId).then((result) => {
		if (result) {
			return dispatch({ type: FETCH_CAN_RESEND_SURVEY_REMINDER_SUCCESS, data: result.data })
		} else {
			return dispatch({ type: FETCH_CAN_RESEND_SURVEY_REMINDER_FAILED, data: {} })
		}
	})
	return
}

const RESEND_SURVEY_REMINDER_REQUESTED = 'RESEND_SURVEY_REMINDER_REQUESTED'
const RESEND_SURVEY_REMINDER_SUCCESS = 'RESEND_SURVEY_REMINDER_SUCCESS'
const RESEND_SURVEY_REMINDER_FAILED = 'RESEND_SURVEY_REMINDER_FAILED'

export const resendSurveyReminder = (caseId, instanceId) => (dispatch) => {
	dispatch(startSaving())
	dispatch({ type: RESEND_SURVEY_REMINDER_REQUESTED, data: {} })

	survey.resendSurveyReminder(caseId, instanceId).then((result) => {
		dispatch(stopSaving())
		dispatch(hideModal())
		if (result && result.isSuccessful) {
			dispatch({ type: RESEND_SURVEY_REMINDER_SUCCESS, data: result.data })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.survey.resendReminderSuccess', isSuccess: true }
			})
		} else {
			dispatch({ type: RESEND_SURVEY_REMINDER_FAILED, data: {} })
			if (result && result.errorCode === 38) {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.survey.resendReminderLimitExceededError', isSuccess: false }
				})
			}
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.survey.resendReminderError', isSuccess: false }
			})

		}
	})
	return
}

const FETCH_BILATERAL_AREAS_REQUESTED = 'FETCH_BILATERAL_AREAS_REQUESTED'
const FETCH_BILATERAL_AREAS_SUCCESS = 'FETCH_BILATERAL_AREAS_SUCCESS'
const FETCH_BILATERAL_AREAS_FAILED = 'FETCH_BILATERAL_AREAS_FAILED'

export const fetchBilateralAreas = (versionId) => (dispatch) => {
	dispatch({ type: FETCH_BILATERAL_AREAS_REQUESTED, data: {} })

	protocol.fetchBilateralAreas(versionId).then((data) => {
		if (data && data.isSuccessful) {
			return dispatch({ type: FETCH_BILATERAL_AREAS_SUCCESS, data: data })
		} else {
			return dispatch({ type: FETCH_BILATERAL_AREAS_FAILED, data: {} })
		}
	})
	return
}


const initial = {
	observationalProtocol: null,
	observationalProtocolPreTreatmentSurveys: [],
	observationalProtocolTreatmentSurveys: [],
	observationalProtocolPostTreatmentSurveys: [],
	observationalProtocols: [],
	survey: null,
	selectedSurveyDelegateId:null,
	selectedSurveyTask36Id:null,
	originalSurvey: null,
	surveyQuestions: null,
	surveyQuestionIds: null,
	surveyQuestionHash: null,
	originalSurveyQuestions: null,
	originalSurveyQuestionIds: null,
	originalSurveyQuestionHash: null,
	questions: [],
	revisions: [],
	isCreating: false,
	surveyRefreshed: false,
	surveyQuestionsLoading: false,
	isCreatingSurvey: false,
	sortField: 'name',
	isDescending: true,
	filter: 0,
	hasProtocols: false,
	units: [ { id: 0, label: 'Inches' }, { id: 1, label: 'Centimeters' } ],
	autoCompleteSuggestions: [],
	yAxisAutoCompleteSuggestions: [],
	autoCompleteChildSuggestions: [],
	status: 0,
	areAnyAnsweredQuestion: false,
	protocolVersions: [],
	canResendSurveyReminders: [],
	bilateralAreas: null,
	patientCaseSurveyInstances: null
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_CHILD_PROTOCOL_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteChildSuggestions: action.data }
		case FETCH_PROTOCOL_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestions: action.data }
		case FETCH_OBSERVATIONAL_PROTOCOL_REQUESTED:
			return {
				...state,
				observationalProtocol: null,
				observationalProtocolPreTreatmentSurveys: [],
				observationalProtocolTreatmentSurveys: [],
				observationalProtocolPostTreatmentSurveys: [],
                revisions: []
			}
			break
		case FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS:
			return {
				...state,
				observationalProtocol: action.data,
				observationalProtocolPreTreatmentSurveys: action.data && action.data.preTreatmentSurveys,
				observationalProtocolTreatmentSurveys: action.data && action.data.treatmentSurveys,
				observationalProtocolPostTreatmentSurveys: action.data && action.data.postTreatmentSurveys
            }
		case FETCH_OBSERVATIONAL_PROTOCOLS_SUCCESS:
			return {
				...state,
				observationalProtocols: action.data,
				hasProtocols: action.data.length > 0
			}
		case FETCH_SURVEY_SUCCESS:
			return {
				...state,
				survey: action.data.survey,
				originalSurvey: action.data.survey,
				surveyQuestions: action.data.list,
				surveyQuestionHash: action.data.nodes,
				surveyQuestionIds: action.data.flat,
				originalSurveyQuestions: action.data.list,
				originalSurveyQuestionHash: action.data.nodes,
				originalSurveyQuestionIds: action.data.flat,
				patientCaseSurveyInstances: action.data.patientCaseSurveyInstances,
				surveyRefreshed: true,
				surveyQuestionsLoading: false
			}
		case FETCH_SURVEY_QUESTIONS_REQUESTED:
			return {
				...state,
				survey: null,
				originalSurvey: null,
				surveyQuestions: null,
				surveyQuestionHash: null,
				surveyQuestionIds: null,
				originalSurveyQuestions: null,
				originalSurveyQuestionHash: null,
				originalSurveyQuestionIds: null,
				patientCaseSurveyInstances: null,
				surveyRefreshed: true,
				surveyQuestionsLoading: true
			}
		case FETCH_SURVEY_VALUES_REQUESTED:
			return {
				...state,
				surveyId: null,
				survey: null,
				originalSurvey: null,
				surveyQuestions: null,
				surveyQuestionHash: null,
				surveyQuestionIds: null,
				originalSurveyQuestions: null,
				originalSurveyQuestionHash: null,
				originalSurveyQuestionIds: [],
				patientCaseSurveyInstances: null,
				surveyRefreshed: true
			}
		case FETCH_SURVEY_VALUES_SUCCESS:
			return {
				...state,
				status: action.data.status,
				surveyId: action.data.surveyId,
				survey: action.data.survey,
				originalSurvey: action.data.survey,
				surveyQuestions: action.data.list,
				surveyQuestionHash: action.data.nodes,
				surveyQuestionIds: action.data.flat,
				originalSurveyQuestions: action.data.list,
				originalSurveyQuestionHash: action.data.nodes,
				originalSurveyQuestionIds: action.data.flat,
				patientCaseSurveyInstances: action.data.patientCaseSurveyInstances,
				surveyRefreshed: true,
				revisions: action.data.revisions
			}		
		case FETCH_SURVEY_QUESTIONS_SUCCESS:
			return {
				...state,
				questions: action.data
			}
		case SUBMIT_SURVEY_ANSWER_SUCCESS:
			return {
				...state,
				status: action.data.status,
				isCreatingSurvey: action.data.isCreatingSurvey
			}
		case SUBMIT_SURVEY_ANSWER_FAILED:
			return {
				...state,
				isCreatingSurvey: action.data.isCreatingSurvey
			}
		case SUBMIT_SURVEY_ANSWER_REQUESTED:
			return {
				...state,
				isCreatingSurvey: action.data.isCreatingSurvey
			}
		case RESET_SURVEY:
			return {
				...state,
				survey: null,
				originalSurvey: null,
				surveyQuestions: null,
				surveyQuestionHash: null,
				surveyQuestionIds: null,
				originalSurveyQuestions: null,
				originalSurveyQuestionHash: null,
				originalSurveyQuestionIds: null,
				patientCaseSurveyInstances: null,
				surveyRefreshed: false
			}
		case SET_ARE_ANY_ANSWERED_QUESTION_SUCCESS:
            return {
                ...state,
				areAnyAnsweredQuestion: action.data
            }
		case FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_FAILED:
		case FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_REQUESTED:
		case CLEAR_PROTOCOL_VERSIONS_REQUESTED:
			return {
				...state,
				protocolVersions: []
			}
		case FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_SUCCESS:
			return {
				...state,
				protocolVersions: action.data
			}
		case FETCH_CAN_RESEND_SURVEY_REMINDER_REQUESTED:
			return {
				...state,
				canResendSurveyReminders: []
			}
		case FETCH_CAN_RESEND_SURVEY_REMINDER_SUCCESS:
			return {
				...state,
				canResendSurveyReminders: action.data
			}

		case FETCH_BILATERAL_AREAS_FAILED:
		case FETCH_BILATERAL_AREAS_REQUESTED:
			return {
				...state,
				bilateralAreas: null
			}
		case FETCH_BILATERAL_AREAS_SUCCESS:
			return {
				...state,
				bilateralAreas: action.data
			}

		case 'SIGN_OUT_REQUESTED':
			return initial
	
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.protocol
const filterSelector = (state) => state.protocol.filter
export const unitListSelector = (state) => state.protocol.units

export const surveyQuestionIdSelector = (state) => state.protocol.surveyQuestionIds
export const surveyRefreshedSelector = (state) => state.protocol.surveyRefreshed
export const surveyQuestionsLoadingSelector = (state) => state.protocol.surveyQuestionsLoading

export const hasProtocolSelector = createSelector(mainSelector, (state) => {
	return state.hasProtocols
})
export const sortProtocolsDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortProtocolsFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const isCreatingProtocolSelector = createSelector(mainSelector, (state) => state.isCreating)

export const isCreatingSurveySelector = createSelector(mainSelector, (state) => state.isCreatingSurvey)

export const protocolSelector = createSelector(mainSelector, (state) => state.observationalProtocol)
export const preTreatmentSurveySelector = createSelector(
	mainSelector,
	(state) => state.observationalProtocolPreTreatmentSurveys
)
export const treatmentSurveySelector = createSelector(
	mainSelector,
	(state) => state.observationalProtocolTreatmentSurveys
)
export const postTreatmentSurveySelector = createSelector(
	mainSelector,
	(state) => state.observationalProtocolPostTreatmentSurveys
)

export const protocolsSelector = createSelector(mainSelector, filterSelector, (state, filter) => {
	switch (filter) {
		case 0:
			return state.observationalProtocols
		case 1:
			return state.observationalProtocols.filter((r) => {
				return r.isActive === true
			})
		case 2:
			return state.observationalProtocols.filter((r) => {
				return r.isActive === false
			})
	}
})

export const formattedProtocolsSelector = createSelector(mainSelector, (state) => {
	if (state && state.observationalProtocols) {
		return state.observationalProtocols.map((r) => {
			return {
				id: r.id,
				name: r.name,
				label: r.name,
				isActive: r.isActive
			}
		})
	}
	return []
})

export const childProtocolListSelector = createSelector(mainSelector, (state) => {
	if (state.observationalProtocol && state.observationalProtocol.children) {
		return state.observationalProtocol.children.map((r) => {
			return {
				label: r.name,
				id: r.id
			}
		})
	}
	return null
})

export const surveySelector = createSelector(mainSelector, (state) => {
	return state.survey
})

export const surveyFormSelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyId) {
		return 'form' + state.surveyId
	}
})

export const surveyQuestionsSelector = createSelector(mainSelector, (state) => {
	return state.surveyQuestions && state.surveyQuestions.getList()
})

export const areAnyAnsweredQuestionSelector = createSelector(mainSelector, (state) => {
	return state.areAnyAnsweredQuestion
})

// export const surveyDelegateSelector = createSelector(mainSelector, (state) => {
// 	return
// })

export const flatSurveyQuestionsSelector = createSelector(mainSelector, surveyQuestionIdSelector, (state, ids) => {
	if (state && ids && ids.length > 0) {
		let questions = []

		ids[0].map((r) => {
			if (state.surveyQuestionHash[0].hasOwnProperty(r)) {
				let x = state.surveyQuestionHash[r]

				questions.push(x)
			} else {
				console.info('No Property', questions)
			}
		})

		return questions
	}
})

export const protocolAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestions) {
		return state.autoCompleteSuggestions
	}
	return []
})
export const protocolAutoCompleteChildSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteChildSuggestions) {
		return state.autoCompleteChildSuggestions
	}
	return []
})

// export const questionsSelector = createSelector(mainSelector, (state) => {
// 	return (
// 		state.questions &&
// 		state.questions.questions &&
// 		state.questions.questions.length > 0 &&
// 		state.questions.questions.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
// 	)
// })

export const yAxisAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.yAxisAutoCompleteSuggestions) {
		return state.yAxisAutoCompleteSuggestions
	}
	return []
})

export const questionListSelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyQuestions) return state.surveyQuestions
})

export const questionHashSelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyQuestionHash) return state.surveyQuestionHash
})

export const questionIdSelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyQuestionIds) return state.surveyQuestionIds
})

export const questionsSelector = createSelector(
	mainSelector,
	questionIdSelector,
	questionHashSelector,
	(state, idsContainers, hash) => {
		if (state && idsContainers && idsContainers.length > 0 && hash) {
			let questionContainers = []

			idsContainers
				.map((ids, index) => {
					let questions = []

					ids.map((r) => {
						if (hash[index].hasOwnProperty(r)) {
							let x = hash[index][r]
							questions.push(x)
						} else {
							console.info('No Property', questions)
						}
					})

					questionContainers.push(questions)
				})

			return questionContainers
		}
	}
)

export const surveyAnswerRevisionsSelector = createSelector(mainSelector, (state) => {
	if (state && state.revisions) return state.revisions
})

export const initialValuesSelector = createSelector(
	mainSelector,
	questionIdSelector,
	questionHashSelector,
	(state, ids, hash) => {
		var initialObj = {}

		initialObj['answers'] = [ 2 ]
		return initialObj
	}
)

export const protocolVersionsSelector = createSelector(mainSelector, (state) => {
	return state && state.protocolVersions
})

export const defaultProtocolVersionSelector = createSelector(mainSelector, (state) => {
	if(state && state.protocolVersions && state.protocolVersions.length > 0)
		return state.protocolVersions.find(x => x.isDefault)
})

export const canResendSurveyRemindersSelector = createSelector(mainSelector, (state) => {
	return state && state.canResendSurveyReminders
})

export const bilateralAreasSelector = createSelector(mainSelector, (state) => {
	return state && state.bilateralAreas
})
