import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import logoImg from '../../assets/shared/logo_circles_white.svg'; // Adjust the path based on your folder structure
import PropTypes from 'prop-types'; // For type-checking

// Define styles using Material-UI's makeStyles
const useStyles = makeStyles((theme) => ({
  pageLoginText: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    margin: '20px auto 0',
    maxWidth: '560px',
    padding: '30px',
    minHeight: 'unset',
    [theme.breakpoints.up('md')]: {
			minHeight: '496px',
		},
  },
  headerText: {
    color: '#fff',
    textAlign: 'left',
    margin: '0 0 20px',
    fontFamily: 'Book Antique',
    fontSize: 'clamp(58px, 7vw, 98px)',
    textTransform: 'uppercase',
    fontWeight: 600,
    letterSpacing: 'clamp(0.5px, 0.4vw, 1rem)',
    position: 'relative',
  },
  logoImg: {
    width: 'clamp(32px, 3.4vw, 57px)',
    position: 'absolute',
    top: '0',
    transform: 'translate(-71%, -22%)',
  },
  welcomeMessage: {
    color: '#fff',
    marginBottom: '20px',
    fontFamily: 'Book Antique',
    fontSize: '18px',
    lineHeight: 1.8,
  },

}));

const WelcomeText = ({ intl, headerTextId, welcomeMessageId, contactUsId, contactUsLinkId }) => {
  const classes = useStyles();

  return (
    
      <div className={classes.pageLoginText}>
        <Typography data-testid="form-login-header" className={classes.headerText}>
          <img src={logoImg} className={classes.logoImg} alt="Logo" />
          {intl.formatMessage({ id: headerTextId })}
        </Typography>
        <Typography data-testid="form-login-welcome" variant="body2" className={classes.welcomeMessage}>
          {intl.formatMessage({ id: welcomeMessageId })}
        </Typography>
        <Typography data-testid="form-login-contactUs" variant="body2" className={classes.welcomeMessage}>
          {intl.formatMessage(
            { id: contactUsId },
            {
              link: (
                <a href="https://www.rgnmed.com/support/contact-us" target="_blank" style={{ color: 'rgb(255, 255, 255)' }}>
                  {intl.formatMessage({ id: contactUsLinkId })}
                </a>
              ),
            }
          )}
        </Typography>
      </div>
    
  );
};

WelcomeText.propTypes = {
  intl: PropTypes.object.isRequired,
  headerTextId: PropTypes.string.isRequired,
  welcomeMessageId: PropTypes.string.isRequired,
  contactUsId: PropTypes.string.isRequired,
  contactUsLinkId: PropTypes.string.isRequired,
};

export default WelcomeText;
